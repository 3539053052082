import { useRef } from "react";
import * as S from "./AboutMe.styles";
import { useIsVisible } from "../../utils/useVisible";
import aboutMe from "../../images/aurea_photo.jpg";
import { IoIosArrowDown } from "react-icons/io";

export const AboutMe = () => {
  const ref = useRef<HTMLDivElement>(null);

  const isVisible = useIsVisible(ref);

  return (
    <>
      <IoIosArrowDown
        style={{ cursor: "pointer", alignSelf: "center", marginTop: "20px" }}
        size={50}
        color={"#824427"}
        onClick={() => {
          document
            .getElementById("about-me")
            ?.scrollIntoView({ behavior: "smooth" });
        }}
      />
      <S.AboutMeWrapper id="about-me">
        <S.AboutMeTopDivider />
        <S.AboutMeImage
          src={aboutMe}
          alt="Aurea Logo Branca"
          width={380}
          height={500}
        />

        <S.AboutMeTextWrapper ref={ref} applyanimation={isVisible.toString()}>
          <h2>Sobre mim</h2>
          <p>
            Olá, me chamo Áurea Gomes, sou psicóloga clinica CRP 15-7553,
            formada pela Universidade Federal de Alagoas (UFAL) e pós graduanda
            em Neuropsicologia e Terapia Cognitivo-Comportamental(TCC).
            <br /> Realizo atendimentos de forma online e presencial em
            Maceió/AL.
            <br />
            <br />
            Atuo com adultos e adolescentes utilizando a TCC, que relaciona
            nossos pensamentos, comportamentos e sentimentos.
            <br />
            <br />
            Atendo há bastante tempo e sei que a terapia é um espaço seguro e
            acolhedor para autoconhecimento e transformação. Estou aqui para te
            auxiliar a superar seus desafios e a viver uma vida com mais
            bem-estar.
            <br />
            <br />
            <q>
              <i>
                A Terapia Cognitivo-Comportamental nos ensina que, ao modificar
                nossos pensamentos, podemos transformar nossas emoções e
                comportamentos.
              </i>
            </q>
            <br />
            <br />- Judith Beck
          </p>
          <S.Button target="_blank" href="https://wa.link/netv6h">
            Agende sua consulta
          </S.Button>
        </S.AboutMeTextWrapper>
        <S.AboutMeBottomDivider />
      </S.AboutMeWrapper>
    </>
  );
};
