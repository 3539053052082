import { IoIosArrowDown } from "react-icons/io";
import * as S from "./Info.styles";

export const Info = () => {
  return (
    <S.InfoWrapper id="info">
      <IoIosArrowDown
        style={{ cursor: "pointer" }}
        size={50}
        color={"#824427"}
        onClick={() => {
          document
            .getElementById("info")
            ?.scrollIntoView({ behavior: "smooth" });
        }}
      />
      <div
        style={{
          width: "70vw",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          border: "1px solid #979e7e",
          borderRadius: "25px 25px 50px 50px",
          marginTop: "10px",
          paddingBottom: "15px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <S.InfoHeaderWrapper>
          <h2>Como funciona a terapia?</h2>
        </S.InfoHeaderWrapper>
        <S.InfoBodyWrapper>
          <h4>
            Você já se viu preso em um ciclo interminável de preocupações,
            ansiedade ou tristeza? Às vezes, a vida pode nos lançar desafios que
            parecem insuperáveis. É aí que a terapia entra em cena, como uma
            bússola confiável que te auxilia em meio à tempestade.
            <br />
            <br />
            Imagine finalmente sentir-se livre do peso que tem segurado seus
            ombros. Visualize uma mente clara e tranquila, pronta para enfrentar
            qualquer obstáculo que a vida apresente. A terapia funciona para
            diversos tipos de problemas, como: separações, ansiedade, luto,
            estresse, procrastinação, depressão....
            <br />
            <br />
            Ao embarcar nessa jornada, você não estará sozinho. Vou te auxiliar
            a navegar pelas águas turbulentas dos seus pensamentos e emoções. E
            vamos juntos criar estratégias para lidar com as adversidades.
            <br />
            <br />
            Não deixe que a angústia e o sofrimento dominem sua vida. Dê o
            primeiro passo em direção a uma existência mais plena, significativa
            e leve. A terapia está esperando por você! Permita-se a experiência
            transformadora da terapia hoje mesmo.
          </h4>
          <S.Button target="_blank" href="https://wa.link/netv6h">
            Dê o primeiro passo!
          </S.Button>
        </S.InfoBodyWrapper>
      </div>
    </S.InfoWrapper>
  );
};
