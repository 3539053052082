import styled from "styled-components";

export const MapWrapper = styled.div`
  background: linear-gradient(
    135deg,
    rgba(135, 171, 152, 1) 52%,
    rgba(55, 60, 56, 1) 100%
  );
  padding: 16px;
  padding-bottom: 100px;
  flex-direction: column;
  justify-content: center;
  height: 500px;
  align-items: center;
  margin-bottom: 50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 100px 150px 50px 50px;  
  h2 {
    text-align: center;
    align-self: center;
    font-size: 1.25rem;
    font-weight: 300;
    color: white;
    margin-bottom: 1rem;
    font-family: colabero;
  }
  @media (min-width: 425px) {
    h2 {
      font-size: 1.5rem;
    }
  }
`;