import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import * as S from "./place.styles";

export const Place = () => {
  const position = { lat: -9.643668196898572, lng: -35.71134599260189 };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    process.env.ENABLE_MAP === 'true' ? (<S.MapWrapper id="map">
      <h2>Onde estou?</h2>
      <GoogleMap
        zoom={15}
        center={position}
        mapContainerStyle={{ width: "100%", height: "400px" }}
      >
        <Marker position={position} />
      </GoogleMap>
    </S.MapWrapper>) : <></>
  );
};
