import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;
export const AboutMeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  position: relative;
  justify-content: space-between;
  gap: 8px;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 24px;
    padding: 50px 75px 12px 75px;
    gap: 25px;
  }
  @media (min-width: 1260px) {
    margin-top: 48px;
    flex-direction: row;
    padding: 75px 75px 37px 75px;
    gap: 50px;
  }
`;

export const AboutMeImage = styled.img`
  border-radius: 5px;
  width: 242px;
  height: 364px;
  align-self: center;
  margin-top: 50px;
  @media (min-width: 768px) {
    align-self: start;
    margin-top: 0px;
    width: 380px;
    height: 500px;
  }
`;

export const AboutMeTopDivider = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  border: solid 1px var(--primary);
  border-right: none;
  border-bottom: none;
  top: 15px;
  left: 15px;
`;

export const AboutMeBottomDivider = styled.div`
  bottom: 15px;
  right: 15px;
  position: absolute;
  width: 100px;
  height: 100px;
  border: solid 1px var(--primary);
  border-top: none;
  border-left: none;
`;

export const AboutMeTextWrapper = styled.div<{ applyanimation: string }>`
  display: flex;
  flex-direction: column;
  padding: 50px;
  animation: ${(props) => (props.applyanimation === "true" ? fadeIn : "none")}
    1s;
  h2 {
    font-size: 20px;
    font-weight: 900;
    color: var(--primary);
    margin-bottom: 1.25rem;
    font-family: colabero;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    color: var(--primary);
    margin-bottom: 1rem;
  }
  q {
    font-weight: 300;
    font-size: 16px;
  }

  @media (min-width: 425px) {
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
    }
  }
  @media (min-width: 720px) {
    padding: 16px;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
    }
  }
  @media (min-width: 1024px) {
    padding: 16px;
    h2 {
      font-size: 32px;
    }
    p {
      font-size: 20px;
    }
    q {
      font-size: 20px;
    }
  }
`;

export const Button = styled.a`
  align-self: end;
  position: static;
  border-radius: 5px;
  background-color: #60644d;
  color: #60644d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  color: white;
  padding: 0px;
  width: 175px;
  height: 40px;
  font-size: 12px;
  gap: 10px;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
    background-color: #888e6d;
    box-shadow: 2px 2px 5px white;
  }
  @media (min-width: 768px) {
    width: 250px;
    height: 50px;
    font-size: 16px;
    gap: 10px;
    margin-top: 16px;
  }
`;
