import * as S from "./Banner.styles";
import { MdWhatsapp } from "react-icons/md";
import { HiOutlinePhone } from "react-icons/hi";
import { FiInstagram } from "react-icons/fi";
import bannerImage from "../../images/banner.svg";
import placeholder from "../../images/placeholder.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Banner = () => {
  return (
    <S.BannerWrapper>
      <S.LeftContent>
        <S.BannerTitle>
          Aprenda a lidar
          <br /> com seus
          <br /> pensamentos.
        </S.BannerTitle>
        <S.BannerSubTitle>Áurea Gomes | Psicóloga Clínica</S.BannerSubTitle>
        <S.BannerBody>
          Aqui você vai encontrar um trabalho <strong>especializado</strong> e
          com <strong>evidências</strong> cientificas de sua efetividade para
          diminuir o seu sofrimento mental e trazer <strong>leveza</strong> para
          sua vida.
          <br />
          <br />
          Entre em contato para saber mais sobre os atendimentos e agende sua
          consulta agora.
        </S.BannerBody>
        <S.BannerButton target="_blank" href="https://wa.link/netv6h">
          <MdWhatsapp size={28} />
          Agendar Consulta!
        </S.BannerButton>
        <S.PhoneWrapper>
          <HiOutlinePhone />
          <S.BannerBody>(82)993416300</S.BannerBody>
        </S.PhoneWrapper>
        <S.PhoneWrapper>
          <FiInstagram />
          <S.BannerBody>psi.aureagomes</S.BannerBody>
        </S.PhoneWrapper>
      </S.LeftContent>
      <LazyLoadImage 
        src={bannerImage}
        placeholderSrc={placeholder}
        alt="Image Alt"
        effect="blur"
      />
    </S.BannerWrapper>
  );
};
