"use client";

import * as S from "./page.styles";
import { Banner } from "./components/Banner/Banner";
import { Header } from "./components/Header/Header";
import { AboutMe } from "./components/AboutMe/AboutMe";
import { Services } from "./components/Services/Services";
import { Info } from "./components/Info/Info";
import { useEffect, useState } from "react";
import { Place } from "./components/Place/place";
import { MdWhatsapp } from "react-icons/md";
import ReactGA from "react-ga4";
import { GoogleTagManagerProvider } from "@tracktor/react-google-tag-manager";
import { BannerBody, PhoneWrapper } from "./components/Banner/Banner.styles";
import { HiOutlinePhone } from "react-icons/hi";
import { FiInstagram } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { MdOutlinePlace } from "react-icons/md";
import { TbTrident } from "react-icons/tb";

export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setInterval(() => {
      if (process.env.REACT_APP_GA_ID) {
        ReactGA.initialize(process.env.REACT_APP_GA_ID);
      }
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <div />;
  }

  return (
    <GoogleTagManagerProvider id={process.env.REACT_APP_GTM_ID}>
      <S.Container>
        <meta name="keywords" content="Psicologa, Áure Gomes, Terapia, TCC" />
        <meta name="title" content="Psicóloga Áurea Gomes" />
        <meta
          name="description"
          content="Psicologa clinica, atuando com teoria cognitivo comportamental CRP 15-7553, atendimento presencial e online em Maceió/AL."
        />
        <Header />
        <Banner />
        <Info />
        <AboutMe />
        <Services />
        <Place />
        <S.Footer>
          <S.FooterItem>
            <PhoneWrapper>
              <TbTrident />
              <BannerBody>CRP: 15/7553</BannerBody>
            </PhoneWrapper>
            <PhoneWrapper>
              <MdOutlineMail />
              <BannerBody>aureapsicologamcz@gmail.com</BannerBody>
            </PhoneWrapper>
          </S.FooterItem>
          <S.FooterItem>
            <PhoneWrapper>
              <HiOutlinePhone />
              <BannerBody>(82)993416300</BannerBody>
            </PhoneWrapper>
            <PhoneWrapper>
              <FiInstagram />
              <BannerBody>psi.aureagomes</BannerBody>
            </PhoneWrapper>
          </S.FooterItem>
          <S.FooterItem>
            <PhoneWrapper>
              <MdOutlinePlace />
              <BannerBody>
                Edf. Premium Office, Av. Gov. Osman Loureiro, 3506 -
                Mangabeiras, Maceió - AL, 57037-630
              </BannerBody>
            </PhoneWrapper>
            <PhoneWrapper>
              <MdOutlinePlace />
              <BannerBody>
                Clínica Enova, Eng°. Otávio Cabral, 363 - Gruta de Lourdes,
                Maceió - AL, 57052-483
              </BannerBody>
            </PhoneWrapper>
          </S.FooterItem>
        </S.Footer>
        <S.WhatsAppWrapper>
          <a target="_blank" href="https://wa.link/netv6h" rel="noreferrer">
            <MdWhatsapp color="white" />
          </a>
        </S.WhatsAppWrapper>
      </S.Container>
    </GoogleTagManagerProvider>
  );
}
